/* 
 * File: Search - Wordpress Plugin
 *
 */


.wp_searchBox {
	// border: 1px solid green; // DEV
	
	} // wp_searchBox


	
form.search-form {
	// border: 1px solid red; // DEV
	
	
	
	.screen-reader-text {
		/* color: $c_blue; */
		display: none;
		} // screen-reader-text
	
	// FLEX - TO CHANGE ORDER OF FIELD & BUTTON
	
	// & MUST STTYLE THE BUTTON !!	
	input[type=submit] {
		display: none;
		}	// input
		
		
	input[type=search] {
		// TO HIDE IT, UNLESS i CAN TRANSLATE THE ETXT SOMEHOW...
		/* color: transparent !important; */

		}	// input
	
   	// TO HIDE - PLACEOLDER TEXT
		::-webkit-input-placeholder {font-size: 0px;}
    ::-moz-placeholder {font-size: 0px;}
    :-ms-input-placeholder {font-size: 0px;}
    input:-moz-placeholder {font-size: 0px;} 

		
		
		
	} // search-form	
	
	
