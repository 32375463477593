/* 
 * File: Cookies - Wordpress Plugin
 *
 */
 
 
#catapult-cookie-bar {
	/* 
	
	background-color: $c_bg_main !important;
	color: $c_blue !important; // BLUE TEXT
	border-bottom: 2px solid $c_blue; // BLUE TEXT
	
	 */
	background-color: $c_bg_footer !important;
	color: $c_white !important; // BLUE TEXT
	
	a {
		// color: inherit;
		/* color: $c_blue !important; // BLUE TEXT */
		color: $c_white !important; // BLUE TEXT
		text-decoration: underline;
		&:hover {text-decoration: none;} 
		} // a
	
	button {
		background-color: $c_blue !important; // BLUE TEXT
		color: #fff !important;
		@include transitionGeneral;
		&:hover {
			background-color: lighten($c_blue, 10%) !important; 
			}
		} // button
	
	
	
	} // catapult-cookie-bar
	
	
	
	
	
	
	
	

.has-cookie-bar #catapult-cookie-bar { // ".has-cookie-bar" se asigna por script, osea que hay "FOUC"
	// background-color: red; /* TEST */
	// background-color: $c_bg_main !important;
	/*
	background-color: $c_bg_main;
	color: #525F98; // LBUE TEXT
	border-bottom: 2px solid #525F98; // LBUE TEXT
	*/
	} // catapult-cookie-bar