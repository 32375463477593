/*
* cowoBoilerplate
* Built by cowowo.cat
* Author: Carl Johansson @carlwebdev 
* Project: XXX
* File: Forms
* Filename: _forms.scss
*/



// boilrplate - button disabled ! and button submit vs reset, osea, yes vs no, con diferencia para que se vea qué ahu que clikar UX



/* ====================================================================================================================== */
/* FORMS                                                                                                                  */
/* ====================================================================================================================== */
	


// CSS FORMS !!! - UI - FOCUS FACE IS IMPORTANT, SPECIALLY WEHN TOGGLING WITH TAB THROUGHT THE TAB INDEX


/* INFO FRAMWEORK INPUT HOVER FOCUS ACTIVE ETC ----

Write CSS? 

Please don't forget to add :focus and :active styles along with :hover. :focus is important for keyboard and d-pad users.

Iinput ytops _ HOVER &  FOCUS  ar now ok in my framework (and infoOD & fart be)

BUT DSOES ACTIVE still apply to input typoes??????

*/








/*
button { // necesita mismos tratamientos que input type submit // reset etc
	@extend .buttonMedium;
    width:125px;
	}	
*/




form {
	max-width:400px;
	@include centered;
	
	&.formType_1 {}

	&.formType_2 {} 

} // form




.formLine {	 
	// overflow:hidden; /* if submit is floated (right)) */
	@extend .clearfix; // PRECAUTION FOR FLOATS & SELECT BOXES
	margin-bottom: $spacing;
	&:last-of-type {margin-bottom: 0px;}	
	
	//INSTRUCTION
	.msg_instruction {}
	// ERROR
	.msg_error {}



} // formLine
	
	
	
	
	
label {
	/* LAYOUT */
	margin-bottom: $spacing025;
	display: inline-block; // NEEDED FOR margin-bottom
	/* TEXT */
	color: $c_text_2;
	font-size: $fs_m;
	
	&.is-clickable {}
	cursor:pointer; /* for lables triggering checkboxes and radio buttons :-) */
	
} // label

	




	
input[type=text],
input[type=password], 
input[type=email],
input[type=search],
select, 
textarea, 
input[type=file] {
	width:100%; 
	// margin-bottom:10px;
	
	
	@include f_inputs; // !!!
	}
	
	 
input[type=submit],
input[type=reset] {
	/*
	@extend .buttonMedium;
	float:left;
	*/
	border:none;	
	@extend .buttonMedium;
  // width:125px;
	}		
/*
input[type=reset] {
	@extend .buttonSmall;
	}
*/		
	

input[type=text], // USING FOR SEARCH BOX !!!!
input[type=password],
input[type=email],
input[type=search],
select,
textarea  {
	// background-color:$c_divider;
    // background-color: none;
    background-color: transparent;
  
	// border:3px solid $c_primary;
    // border:3px solid $c_blue;
    border:3px solid transparent; // ?
    border-bottom: 2px solid $c_blue;
  
	// padding: $spacing05 $spacing05;
    // padding: $spacing025 $spacing025;
    // padding: $spacing025 0px;
    // padding: $spacing0125 0px;
    padding-bottom: 2px;
	}
	
	
	
	
		
/* 
 * FOCUS and ANIMATIONS 
 */
input[type=text], 
input[type=password], 
input[type=email],
input[type=search],
select, 
textarea {
	@include transitionButton; // change to general
	}

/* 
 * HOVER must be before ACTIVE 
 */
input[type=text]:hover, 
input[type=password]:hover, 
input[type=email]:hover,
input[type=search]:hover,
select:hover, 
textarea:hover {
	// background:#ff9999;
	/* border: 1px solid #D3D3D3; */
	}

/* 
 * ACTIVE must be after HOVER 
 */
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=search]:focus,
textarea:focus,
select:focus {
	outline: none; /* VIP */
	// background-color:#fff;
	// border:3px solid $c_primary_dark;
	}
	
	
// be more specific . with warpper fro newsletter seach box, etc scaneraios diefreente.	
	
	
/* & meter lo de los checkboxes !!!!!!!!!!!!!!!!!!!!!! */	
	/* scale ?? */
	
	

/* FRAMEWORK - input type image */

/* input[type=file] & label trick */
/* THE HTML
        <label class="filebutton">
        Seleccionar arxiu
        <span><input type="file" name="doiser" id="dosier" /></span>
        </label>
*/	

label.filebutton {
    width:120px;
    height:40px;
    overflow:hidden;
    position:relative;
    background-color:#fff;
	border:1px solid #ccc;
	padding:0 5px;
}
label.filebutton:hover {
    background-color:#ddd;
}

label span input {
    z-index: 999;
    line-height: 0;
    /* font-size: 50px; */
	font-size: 15px;
    position: absolute;
    /* top: -2px; */
	top: -4px;
    /* left: -700px; */
	left: -245px;
    opacity: 0;
    filter: alpha(opacity = 0);
    -ms-filter: "alpha(opacity=0)";
    cursor: pointer;
    /* _cursor: hand; */
    margin: 0;
    padding:0;
}
/* END OF - FORM - label covering input[type=file] */

label {cursor:pointer;} /* for lables triggering checkboxes and radio buttons :-) */




	
/* ADD ALSO PLACEHOLDER TEXT THINGEES !!!!!!!!! - esto va en typogtraphy  */












/* BELONGS HERE IN FORMS IN RESET FILE  / class="css_submit_button" */

/* USE css style for submit button (input) inseide generic_links */
.css_submit_button {cursor:pointer; border:none;
border:2px solid #000; 
padding:0.2rem 0.5rem;
background-color:#bdbec0; /* GRISACEO */
font-family: 'Antonio', sans-serif;
font-weight:bold;
font-size:1.25rem;
color:#000;
}
	.css_submit_button:hover {background-color:#000; color:#0d80a1;}

input[type="submit"].css_submit_button:disabled
{
background:#dddddd; color:#F00; cursor:default;
}



