/*
 * File: PAGE FORBUNDET
 */


.m_infoMatrix { // ANY NAME
	
	.col {
		float: left;
		/* 2 COLS */
		&:first-of-type {width: 28%;}
		&:last-of-type {width: 72%;}
		
		@media only screen and (max-width: $bp_l) {
			&:first-of-type {@include fullwidth;}
			margin-bottom: $spacing05; // TEST
			&:last-of-type {margin-bottom: 0%;} // PRECAUTION							
			} // MEDIA-QUERY	
		
		@media only screen and (max-width: $bp_m) { // $bp_s 
      &:last-of-type {@include fullwidth;} 						
		  } // MEDIA-QUERY
      
		} // col
	
	.item {
		@extend .clearfix; // FLOATS ...
		// margin-bottom: $spacing;
		// margin-bottom: $spacing2; // TEST
		margin-bottom: 36px; // TEST
		&:last-of-type {margin-bottom: 0%;} // PRECAUTION	
			
		.title {
			@include f_special;
			font-size: $fs_xl; // TEST
			line-height: 1.3em;
			color: #789FB1;
			// margin-bottom: $spacing05; // demo
			} // title
				
			
		.info {
			color: $c_black;
			// padding: $spacing2 $spacing2 $spacing2 0px;
			
			// font-size: $fs_xl; // TEST - CHWECKING !!! BIG FOR DESKTOP, BUT SPECIALLY FOR MOBILE .. 
			font-size: $fs_l;
			
			
			p {
				margin-bottom: 36px; // TEST
				} // p
			
			
			
			ul {}
			
			li {
				// margin-bottom: $spacing05; // TEST
				margin-bottom: $spacing025; // TEST
				&:last-of-type {margin-bottom: 0%;} // PRECAUTION	
				} // li
			
			a {
				text-decoration: underline;
				&:hover {text-decoration: none;}
				
				} // a
			
			
          
		
			// RWD DEL STAFF:
			li br {display: none;}
		
			@media only screen and (max-width: $bp_m) { // $bp_s 
				li br {display: block;} 	
				
				li {
				margin-bottom: $spacing05; // TEST
				// margin-bottom: $spacing025; // TEST
				// &:last-of-type {margin-bottom: 0%;} // PRECAUTION	
				} // li
              
              } // MEDIA-QUERY            
          
          
          
          
          
          
          
			/*
			.text {
				font-size: $fs_m; // TEST
				} // text
			*/
				
            /* ONLY USED ONCE */ // STILL WORK TO BE DONE HERE 
            .w_columns {
              @extend .clearfix;
              
              .column {
                float: left;
                width: 40%;
                
                // border: 1px solid red; // dev
                
                &:last-of-type {
                  width: 20%;
                  } // last
                
                
                @media only screen and (max-width: $bp_s) {  
                  @include fullwidth;
                  &:last-of-type {@include fullwidth;}
                  margin-bottom: $spacing05; // TEST
                  &:last-of-type {margin-bottom: 0%;} // PRECAUTION	
                  } // MEDIA-QUERY  
                
                
                } // column
              
              
              .icon {
                width: 32px;
                img {@include img;}
                
                } // icon
              
              
              } // w_columns
          
          
          
          
			} // info
			
				
		
		} // item
		
	
	
	} // m_infoMatrix
	
