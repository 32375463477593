/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: a grid ... 
 */






.m_highSchool_hero { // ANY NAME
	background-color: $c_blue;
	@extend .clearfix; // FLOATS ...
	
	
	@media only screen and (max-width: $bp_l) { // ? $bp_m
		// padding-bottom: $spacing; // TEST
		padding-bottom: $spacing2; // TEST
		} // MEDIA-QUERY
			
			
				
	.col {
		float: left;
		width: 50%; /* 2 COLUMNS */
		// @include transitionGeneral; // TEST
		// border: 3px solid red;
		
		@media only screen and (max-width: $bp_l) { // ? $bp_m
			// border: 3px solid green;
			@include fullwidth;	
			/*
			margin-bottom: $spacing;
			&:last-of-type {margin-bottom: 0%;} // PRECAUTION							
			*/
			
			&:last-of-type {
				width: 62%; 
				@include centered;
				} // last
			
			} // MEDIA-QUERY	
			

		@media only screen and (max-width: $bp_m) { // ? $bp_m
			&:last-of-type {width: 80%;} // last
			} // MEDIA-QUERY	
			
		@media only screen and (max-width: $bp_s) { // ? $bp_m
			&:last-of-type {@include fullwidth;	} // last
						
			@include fullwidth;	
			// margin-bottom: $spacing;
			// &:last-of-type {margin-bottom: 0%;} // PRECAUTION							
			} // MEDIA-QUERY	
		
		} // col
	
	
	
	.info {
		color: $c_white;
		padding: $spacing2 $spacing2 $spacing2 0px;
		
		
		@media only screen and (max-width: $bp_l) { // ? $bp_m
			
			
			padding: $spacing2 0px $spacing2 0px;
								
			} // MEDIA-QUERY	
			
			
					
		.title {
			/*
			@include f_special;
			font-size: $fs_xl; // TEST
			line-height: 2rem; // TEST
			*/
			/* TEXT */
			@include f_special;
			font-size: $fs_xxl; // TEST
			line-height: 2.4rem;
	
				
			// margin-bottom: $spacing05; // demo
			margin-bottom: $spacing075; // demo
			} // title
		
		.text {
			// font-size: $fs_m; // TEST
			font-size: $fs_l; // TEST
			} // text
			
			
			
		} // info
		
		
		/*
		.video { 
			// temp img add
			// OPTION B - BACKGORUND IMAGE
			@include bg_img;
			// @include ar_4_3; // ???
			@include ar_16_9; // ???
			background-color: #ccc; // TEMP PLACEHOLDER				
			} // video 	
		*/
	
 /* SCADO AFYUERA COMO MODULE PARA RESULARO EN PAGE STUDIO */
				.m_video {
					// VIDEO AS BG IMAGE OF VIDEO... I HAD THAT WITH THE AIM THIENG
					position: relative; 
					// @include ar_5_4;
					@include ar_4_3;
					// @include ar_16_9;
					/* VIP., SO THE CONTENT OCCUPIES THE REQUIRED SAPCE, AND THE HOVER COVER IS SAME SIZE AS OTHERS (INDEPENDENTLY OF CORRECT VIDEO ASPECT RATIO (precaution)) */
					
					/* VIP */
					overflow: hidden; /* TO HIDE THE PART OF THE VIDEO THAT STICKS OUT - the wrapper should have the proportion of the video, if posibel, or viceversa. Else, hack it this way. */
					
					video {
						width: 100%;
						display: block;
						// border: 3px solid red; // dev
						position: absolute; 
						top:0; 
						width:100%; 
						bottom: 0; /* TEST */
						/*
						display: block; // TEST
						width: 100%;
						*/
						// background-image: /* our video */;
						background-position: center center;
						background-size: contain;
						object-fit: cover; /*cover video background */
						
						/* COPIED FORM fullPage.js demo video background */	
						position: absolute;
						right: 0;
						bottom: 0;
						top:0;
						right:0;
						width: 100%;
						height: 100%;
						background-size: 100% 100%;
						background-color: black; /* in case the video doesn't fit the whole page*/
						// background-image: /* our video */;
						background-position: center center;
						background-size: contain;
						object-fit: cover;  /*cover video background */ /* THE USE OVERFLOW HIDDEN TO HIDE WHAT GOES OVER THE WRAPPER (in this case horizontally)
						object-fit: contain; /* CONTAINS, so in this case I have spaces (black) on top and under */				
						} // video
		
					} // m_video
					
					
					
					
					
	} // m_highSchool_hero
	
	
	
	
	
	

/*
INSTRUCTIONS FOR ANY GRID: 
separate grid behavior (wrapper & cols) from item behavior (and its own rwd behavior)
*/

.m_highSchool_trio { // ANY NAME
	/* background-color: $c_white; */
	// @include paddingv($spacing2);
	@extend .clearfix; // FLOATS ...
	
	
	@include paddingv($spacing); // SOME EXTRA PADDING... TODO
	
	
	.col {
		float: left;
		// width: 32%; /* 3 COLUMNS */
		// margin-right: 2%; // TODO - ENLARGE THIS
		
		width: 30%; /* 3 COLUMNS */
		margin-right: 5%; // TODO - ENLARGE THIS
			
		&:nth-of-type(3n) {margin-right: 0%;}
		
		// border: 1px solid red; // TODO
		
		// RWD, BUT FIRST DO 1+2 
		@media only screen and (max-width: $bp_m) { // ? $bp_m
			&:first-of-type {
				@include fullwidth;
				margin-right: 0px;	
				// margin-bottom: $spacing2; // TEST
				margin-bottom: 5%; // TEST
				} // first
		
			width: 47.5%;
			
								
			} // MEDIA-QUERY
			
			
			
		// RWD - jump to 1 col
		@media only screen and (max-width: $bp_s) { // ? $bp_m
			&:first-of-type {
				// margin-bottom: $spacing2; // TEST
				margin-bottom: $spacing; // TEST
				} // first
				
			@include fullwidth;
			margin-right: 0px;	
			// margin-bottom: $spacing2; // TEST
			margin-bottom: $spacing; // TEST
			&:last-of-type {margin-bottom: 0%;} // PRECAUTION							
			} // MEDIA-QUERY	
		
		} // col
	
	
	
	.item {
			
		.title {
			@include f_special;
			color: $c_blue;
			// font-size: $fs_l; // TEST
			font-size: $fs_xl; // TEST
			line-height: 2.2rem; // ???? !!!
			margin-bottom: $spacing05; // demo
			} // title
		
		.text {
			// font-size: $fs_s; // TEST
			
			// font-size: 30px; // INDESIGN
			font-size: $fs_l; // TEST
			
			} // text
			
		
		
		// RWD - jump to 1 col
		@media only screen and (max-width: $bp_s) { // ? $bp_m
	
			.title {
				// margin-bottom: $spacing05; // demo
				margin-bottom: $spacing0125; // demo
				} // title
					
			} // MEDIA-QUERY	
			
			
						
			
		} // item 	
	
	
	
	} // m_highSchool_trio
	
	
	
	
	
	
	
	
	
	



.m_gridColorful { // ANY NAME
	// background-color: #FBE576; // OJO !!! DIFFRENT YELLOW COLOR PPER ITEM, SO BG COLOR IS DYNAMIC !!!
	
	
	
	.item { 
		@extend .clearfix; // FLOATS ...		
		} // item
	
	
// CASE ALTERNATE POSITION LEFT/RIGHT
.item:nth-of-type(even) {
	// border: 3px solid red;
	
	.col {float: right;} // this is kinda flexbox.
	
	
	// & then undo for rwd...
	
	.info { // maybe this better with html class modifier, annd php counter lie in nli ...
		padding: $spacing2 0px $spacing2 $spacing2;
	
		} // info
	
	} // even, is alternate	
	
	
	
	
	.col {
		float: left;
		width: 50%; /* 2 COLUMNS */
		
		// RWD - jump to 1 col
		@media only screen and (max-width: $bp_s) { // ? $bp_m
			@include fullwidth;	
			margin-bottom: $spacing;
			&:last-of-type {margin-bottom: 0%;} // PRECAUTION							
			} // MEDIA-QUERY	
		
		} // col
	
	
	
	.info {
		
		padding: $spacing2 $spacing2 $spacing2 0px;
		
		.title {
			@include f_special;
			color: $c_blue;
			font-size: $fs_l; // TEST
			line-height: 1.6rem; // ???? !!!
			
			margin-bottom: $spacing05; // demo			
			} // title
		
		.text {
			color: $c_black;
			font-size: $fs_m; // TEST
			
			} // text
			
			
			
		} // info
		
		
		// IMAGE VS SLIDER - ASK EIGIL IF IT'S ALWAYS GOING TO BE CUSTOMIZEABLE LIKE THIS
		.video { 
			// temp img add
			
			// OPTION B - BACKGORUND IMAGE
			@include bg_img;
			// @include ar_4_3; // ???
			@include ar_16_9; // ???
			background-color: #ccc; // TEMP PLACEHOLDER	
			} // video 	
	
	
	
	} // m_gridColorful
	
	
	
	
		
	
	
	
.m_testimonials { // ANY NAME
	// background-color: $c_blue; // 2 DIFFERENT SCENARIOS, bg color & text color
	
	&.case-students {
		background-color: $c_blue;
		.info {color: $c_white;}
		
		padding-bottom: $spacing3;
		
		} // case-students

	&.case-teachers {
		background-color: $c_white;
		.info {color: $c_black;}
		} // case-teachers
	
	
	@include paddingv($spacing2);
	@extend .clearfix; // FLOATS ...
	
	.col {
		float: left;
		// width: 32%; /* 3 COLUMNS */
		width: calc(92% / 3);
		// margin-right: 2%; // TODO - ENLARGE THIS
		margin-right: 4%;
		&:nth-of-type(3n) {margin-right: 0%;}
		
		// RWD - jump to 1 col
		@media only screen and (max-width: $bp_s) { // ? $bp_m
			@include fullwidth;
			margin-right: 0px;	
			// margin-bottom: $spacing;
			margin-bottom: $spacing2;
			&:last-of-type {margin-bottom: 0%;} // PRECAUTION							
			} // MEDIA-QUERY	
		
		} // col
	
	
	
	.item {
		
		
		.image {
			// OPTION B - BACKGORUND IMAGE
			@include bg_img;
			@include ar_square; // ???
			// background-color: #ccc; // TEMP PLACEHOLDER			
			margin-bottom: $spacing075; // TEST
			} // image
		
		
		.info {
			/* color: $c_white; */
						
			.title {
				@include f_main_bold;
				// font-size: $fs_l; // TEST
				font-size: $fs_xl; // TEST
				margin-bottom: $spacing05; // demo
				
				.line1 {}
				.line2 {}
				
				} // title
			
			.text {
				// font-size: $fs_s; // TEST
				font-size: $fs_l; // TEST
				} // text
			.readMore {
				// font-size: $fs_s; // TEST
				font-size: $fs_l; // TEST
				margin-top: $spacing025; // TEST - FROM BELOW..
				a {
					text-decoration: underline;
					&:hover {text-decoration: none;}
					} // a
				} // readMore
			
			} // info
			
		} // item 	
	
	
	
	} // m_testimonials	
	
	
	

		
		
		
.m_gridSchools {
	// @include paddingv($spacing2); // ????
	@extend .clearfix; // FLOATS ...
	
	.col {
		float: left; 
    width: 30%; /* 3 COLUMNS */
		margin-right: 5%; // TODO - ENLARGE THIS
		&:nth-of-type(3n) {margin-right: 0%;}
		// margin-bottom: 5%;
		margin-bottom: 3%; // TEST
		&:last-of-type {margin-bottom: 0%;} // PRECAUTION			

		// pasar a 2 cols ?? if so, then also apply in other places... 
		@media only screen and (max-width: $bp_m) { // 2 COLs
				
			} // MEDIA-QUERY	
			
		@media only screen and (max-width: $bp_s) { // 1 COL
			@include fullwidth; // RESET
			margin-right: 0px; // RESET
			// margin-bottom: $spacing;	
			margin-bottom: $spacing2;					
			} // MEDIA-QUERY	
		
		} // col
	
	
	
	.item {
		
		&.is-active {
			border: 3px solid red; // TEST
			
			} // is-active
		
		
		.image {
			// OPTION B - BACKGORUND IMAGE
			@include bg_img;
			@include ar_square; // ???
			// background-color: #ccc; // TEMP PLACEHOLDER			
			margin-bottom: $spacing075; // TEST
			position: relative;
				
			.imageCover {
				position: absolute;
				top: 0px;
				width: 100%;
				height: 100%;
				
				a {
					display: block;
					height: 100%;
					} // a
				
				} // imageCover
			
			
			} // image
			
			
		
		
		
		
		
		.info {
			color: $c_blue;
			// text-decoration: underline;
			
			.title {
				// color: $c_white;
				// font-weight: bold;
				// font-size: $fs_l; // TEST
				font-size: $fs_xl; // TEST
				// margin-bottom: $spacing05; // demo
				
				.line1 {}
				.line2 {}
				
				a {
					text-decoration: underline;
					&:hover {text-decoration: none;}
					} // a
									
				} // title
			
			} // info
			
		} // item 	
	
	
	
	} // m_gridSchools	
	
	
	

		
		
		
		
				