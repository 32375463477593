/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Reset
 * File: Layout
 */

// HTML5 TAGS - main, article, etc 
// https://www.tutorialrepublic.com/html-reference/html5-tags.php
// ARTICLE
// https://www.tutorialrepublic.com/html-reference/html5-article-tag.php	

body {
	/* background-color: $c_white; */
	// bg color - depends on the page
	// HOMEPAGE IS the base, same as pedagogikk
	background-color: $c_bg_main;
	
	&.case-schools {background-color: $c_bg_schools;} // 2 - vare skoler (salmoncito)
	&.case-highschool {background-color: $c_bg_highschool;} // 3 - high school - yelllow
	&.case-teacher {background-color: $c_bg_teacher;} // 4 - bli laerer - green
	&.case-login {background-color: $c_bg_login;} // 5 - logg in - salmon dark / // DESIGN ?!??! - it's an extenral page - add to menu !!
  } // body


#page {
	/* PLACEHOLDER */
	}

	/*
	header {
		background-color: $c_primary;  
		}
	footer {
		// background-color: $c_bg_footer;
		}		
	*/
		
.frame { 
	@include centered;
	/* max-width:1300px; */
	max-width:1140px;
	// max-width:840px; // TODO - KILL
	min-width:320px; 
	@include paddingh($spacing);	
	@include transition(padding-left, $t_slow);
	@include transition(padding-right, $t_slow);
	
	@media only screen and (max-width: $bp_m) { 
		// @include paddingh($spacing05);	
		@include paddingh($spacing075);	 // PROBABLY STILL TOO LITTLE 			
		} // MEDIA-QUERY	

  } // frame 			
	


section {
	// background-color: #CCC; 
	// margin-bottom: $spacing2; // TEST
	// border: 1px solid red;
	
// CREATE STH FOR GENERIC PAGE CNTENT TOP SPACE, AND BOTTOM SPACE, AS AVARIABLE SOMEHOW... OR AS A DIV DUMMY IN BOTH EXTREMES	
	
	&.genericVertical {
		// @include paddingv($spacing); // TEST
		// @include paddingv($spacing2); // TEST - maybe still too little, or not
		@include paddingv($spacing3); 
		
		@media only screen and (max-width: $bp_m) {
			@include paddingv($spacing2);								
			} // MEDIA-QUERY
			
		} // genericVertical
	
	&.bgWhite {
		background-color: $c_white;
		} // bgWhite
	
	&.bgMain {
		background-color: $c_bg_main;
		} // bgMain
	
	/*
	&.bgMain {
		background-color: $c_bg_main;
		} // bgWhite
	*/
	
	
	
	&.fullHeightTest { 
	 	background-color: #f06; 
		// height: 100%;
		height: 100vh;
	  } // 
	
	/*
	&.test {
		border-bottom: 3px solid black;
		@include paddingv($spacing2); // TEST
		
		background-color: #ccc; 
		&:nth-of-type(odd) {background-color: #999;}
		} // test
	*/
	
	} // section
	

.m_pageTitle {
	/* LAYOUT */
	// background-color: $c_accent; // TEST
	@include paddingv($spacing); // ---  padv vs padh & marv vs marh
	padding-top: $spacing2; // testing
	/* TEXT */
	@include f_special;
	// font-size: $fs_xxl; // TEST
	font-size: 48px; // TEST
	line-height: 2.4rem;
	} // m_pageTitle



.m_sectionTitle { // SMALLER THAN PAGE TITLE
	@include paddingv($spacing); // ---  padv vs padh & marv vs marh
	/* TEXT */
	@include f_special;
	// font-size: $fs_l; // TEST
	// font-size: $fs_xl; 
	font-size: 36px; // TEST
	// line-height: 2.4rem;
	
	&.case-big {		
		/* TEXT */
		@include f_special;
		// font-size: $fs_xxl; // TEST
		font-size: 48px; // TEST
		line-height: 2.4rem;
		
		// padding-top: 0px; // TODO TESTç - COMENTADO, PARA CASE AKTUELT"
		padding-bottom: $spacing05; // TODO TEST
		}
	
	} // m_pageTitle



