/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowoBoilerplate
 * File: Global
 */
 
/*
Susy: Responsive grids for Compass
http://susy.oddbird.net/
susy might not work as such in compass / scout / sass, unless manually downloaded and refernced to susy.
now testing zengrids.com , as it seams to have support inside scout / for compass.
http://zengrids.com/
*/


/* COMPASS */
@import "compass/css3";

/* BASE */
@import "base/reset";
@import "base/sass_style";
@import "base/sass_layout";
@import "base/sass_transitions";
@import "base/typography";
@import "base/typopgraphy_htmleditor";
@import "base/buttons";  /* BEFORE FORMS !!!!!!!! */
@import "base/forms";
@import "base/layout";
// @import "base/layout_stickyFooter";

/* COMPONENTS */
@import "components/header";
@import "components/nav";
@import "components/footer";

@import "components/page_pedagogikk";
@import "components/page_highSchool";
@import "components/page_forbundet";

// @import "components/cookies"; // popup_cookies
@import "components/wp_cookies"; // popup_cookies
@import "components/wp_search"; // WP PLUGIN

@import "components/modules"; // WORKING HERE

@import "components/slick";

/* MODULES */
@import "modules/cowo_accordion"; // maybne use for read more concept...  insetad of a simpel dropdon.. 
@import "modules/cowo_dropdown";