/* CSS Document - FRONT-END */


	
/* 
 * FONTS - FONT-FACE
 */

/* TT Commons Regular */
@font-face {
    font-family: 'tt_commonsregular';
    src: url('../fonts/ttcommons-regular/ttcommons-regular-webfont.woff2') format('woff2'),
         url('../fonts/ttcommons-regular/ttcommons-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* TT Commons Demi Bold */
@font-face {
    font-family: 'tt_commonsdemibold';
    src: url('../fonts/ttcommons-demibold/ttcommons-demibold-webfont.woff2') format('woff2'),
         url('../fonts/ttcommons-demibold/ttcommons-demibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Vesper - Libre (Bold */
@font-face {
    font-family: 'vesper_librebold';
    src: url('../fonts/vesperlibre-bold/vesperlibre-bold-webfont.woff2') format('woff2'),
         url('../fonts/vesperlibre-bold/vesperlibre-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


/* 
 * SASS - VARIABLES - FONTS - "IMPORT" GOOGLE FONTS
 */
/*
@mixin f_roboto { // regular
	font-family: 'Roboto', Arial, sans-serif;
	font-weight: 400;
	}
		@mixin f_roboto_italic {
			@include f_roboto;
			font-style: italic;
			}

	@mixin f_roboto_light { // f_roboto_light
		font-family: 'Roboto', Arial, sans-serif;
		font-weight: 300;
		}	
		@mixin f_roboto_light_italic {
			@include f_roboto_light;
			font-style: italic;
			}					
	@mixin f_roboto_bold {
		font-family: 'Roboto', Arial, sans-serif;
		font-weight: bold;
		// font-weight: 700;
		}
*/

@mixin f_main { // CMS MAIN FONT - for body tag 
    font-family: 'tt_commonsregular';
    }		
@mixin f_main_bold { // for b, strong tags
    font-family: 'tt_commonsdemibold';
    }			
@mixin f_special {  // apply to header tags !!!
    font-family: 'vesper_librebold';
    }		


@mixin f_inputs { // FORM - INPUTS
    @include f_main;
		// font-size: 16px; // TEST
		font-size: 18px; // TEST, for search bar
		// color: red !important;
		color: $c_blue;
    }

@mixin f_placeholders { // FORM - INPUT PLACEHOLDERS
		@include f_main;
		color: $c_text;
		font-weight: 500;	// TEST
		
		// font-size: 18px; // TESTING FOR SEARCH FORM ... 
    }
    ::-webkit-input-placeholder {@include f_placeholders;}
    ::-moz-placeholder {@include f_placeholders;}
    :-ms-input-placeholder {@include f_placeholders;}
    input:-moz-placeholder {@include f_placeholders;} 





/*
// ideas de font size y line height
body{
  font-size:100%;
  line-height: 1.5;
}
*/

body {
	@include f_main;
	// color: $c_text;
	color: $c_blue; // BLUE TEXT
	font-size: 16px; /* DEFAULT /
	/* line-height: $baseSpacing; */	
	}


a, 
a:visited, 
a:hover {
	color: inherit; // NEW
	text-decoration:none; // OJO, montar mixins para tipos de link behaviour  
	// color: $c_text; /* VS color: inherit */
	// ?? default transition - maybe, for color... and boldness... hmmm
	}
	
	
p {
	margin-bottom: $spacing;	
	&:last-of-type {margin-bottom: 0px;}
	}


b, strong {
	font-weight: normal;
	@include f_main_bold;
	
	} // b, strong

// !!!!!!!!!!!!!!!!!!!!!!!!!!
h1, h2 {
	font-weight: 300; // hacky, por los h1 y h2 del header - que hay en esta demo.... hmmm, hacerlo todo mas sencillo to start with !!!!
}




/*  Typography - Headers ======================== */

/*
h1, h2, h3, h4, h5, h6 {
	margin: 10px 0 5px 0;
	// font-family: Arial, Helvetica, sans-serif;
	font-family: Tahoma, Geneva, sans-serif;
	font-weight : normal;
	margin-top: 0px;
	// letter-spacing: -1px;

	}


h1 {font-size: 32px;}
h2 {font-size: 28px;}
h3 {font-size: 24px;}
h4 {font-size: 20px;}
h5 {font-size: 18px;}
h6 {font-size: 16px;}

*/

/*
h1 {
	font-size:4rem;
	// font-family: Arial, Helvetica, sans-serif;
	line-height:1.4;
	color:#000;
	margin-bottom:0.2em;
	}

h2 {
	font-size:2.5rem;
	line-height:1.2;
	color: #222;
	margin-bottom:.5em;
	margin-top:.5em;
	}

h3 {
	font-size : 1.75rem;
	line-height : 1.3; 
	color: #333;
	margin-bottom : 0.3em;
	letter-spacing: -1px;
	}

h4 {
font-size:1.5rem;
	line-height:1.25; 
	color:#444;
	margin-bottom:0.5em;
	}

h5 {
font-size:1.25rem; 
	color:#555;
	margin-bottom:1.25em;
	}

h6 {
	// font-size:1rem; 
	color:#666;

	font-size: $fontsizeMedium; 
	}
*/	
	
	
	
	/*  TEXT: ANCHORS ======================== */
a {
/*color : #cc1122; */
/*
-webkit-transition: all 0.3s ease;
-moz-transition: all 0.3s ease;
-o-transition: all 0.3s ease;
transition: all 0.3s ease;
*/
}

/*
a:visited {color:#ee3344; }
a:focus {outline:thin dotted; color:rgb(0,0,0);}
a:hover, a:active {outline:0; color:#dd2233;}
*/



	
	
	
/** Text / TEXT FORMAT **/
/*
b, strong, .s {font-weight: bolder;} // VS bold ...
i, em, .i {font-style: italic;}
*/

/*  TEXT - PARAGRAPHS ======================== */

p { }


/* and do sth perhaps for p's firts line and first word stufff */



/* CSS HYPHENATION */
/*
p {
-webkit-hyphens: auto;
-moz-hyphens: auto;
hyphens: auto;
}		
*/



/* ADD CODE, PRE, SMAPEL, XMP ETC ETC ETC */
/*

CSS FILE FOR TYPOGRAPHYS...

h headings

p ....

then, 

cursive

pre/code stuff

superscript, subscript ewtc

*/













/*  TEXT: GENERAL TYPOGRAPHY ======================== */

.name {
	font-variant:small-caps; 
	text-transform:capitalize;
	}	/* SHOULD BE A CLASS !!!!!!!!!!!!!!!!!!!!!! */
	
	
	
	
	
/*  TEXT: TYPES OF PARAGRAPHS ======================== */

p.introtext {
font-family:  MuseoSlab100, 'lucida sans unicode', 'lucida grande', 'Trebuchet MS', verdana, arial, helvetica, helve, sans-serif;
font-size : 2.5em; /* 40 / 16 */
color: #333;
line-height: 1.4em;
letter-spacing: -1px;
margin-bottom: 0.5em;
}

p.handwritten {
font-family:  HandSean, 'lucida sans unicode', 'lucida grande', 'Trebuchet MS', verdana, arial, helvetica, helve, sans-serif; 
font-size: 1.375em; /* 24 / 16 */
line-height: 1.8em;
margin-bottom: 0.3em;
color: #666;
}








		