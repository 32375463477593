/* CSS Document - SASS */
/* Front-End */

// 3 SPEEDS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!	

$t_fast: 0.2s;
$t_base: 0.6s;
$t_slow: 1.0s;	
	
	
@mixin transitionGeneral {
	@include transition(all $t_base linear);
	}
// to be used unless more specific 

	
@mixin transitionButton {
	@include transition(all $t_slow linear);
	}
	


