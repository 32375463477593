

.slickslider {
/*
background-color: #f0f;
padding: 100px;
border: 3px solid red;
*/
height: 100%; /* VIP FOR FULLHEIGHT SCENARIO */

// position: relative;

/* border: 30px solid #ff0;	 */


} // ONLY MY DEMO PURPOSES


/* 2 CLASSES INJECTED BY THE SCRIPT */
.slick-list {
	height: 100%;
	position: relative;
	}
	.slick-track {
	height: 100%;
	position: relative
	}
	
	
	
/*

.slickslider_slide {
	background-color: #f0f;
	border: 3px solid red;	
	height: 100%;
	// position: relative;
	// height: 100vh;
	}

*/	
	
.content222 {
	/* margin:auto; */
	/* padding:0px 20px; */ /* ESTO ES LO QUE PERMITE MOSTRAR LOS NAV THINGEES !  */
	/* padding:0px 0px; */
	/* padding:10px 0px; */
	/* width:600px; */
	
	// position:relative;
	
	/* background-color:#ccc; */
	/* padding:10px; */
	}	
	
	
.slick-slide img {
	/* border:5px solid #FFF; */ /* borrar esta linea */
	display:block;
	width:100%;
	}	



/* Slider */
.slick-slider { position: relative; 
               display: block; 
               
               
               box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: none; touch-action: none; -webkit-tap-highlight-color: transparent; }






.slick-list { z-index:100;  position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }

.slick-list:focus { outline: none; }
.slick-loading .slick-list { background: white url(./ajax-loader.gif) center center no-repeat; }
.slick-list.dragging { cursor: pointer; cursor: hand; }

.slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }






.slick-track { position: relative; left: 0; top: 0; display: block; zoom: 1; }
.slick-track:before, .slick-track:after { content: ""; display: table; }
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }



.slick-slide { 
	float: left; 
        // height: 100%; // antes esto no molestaba, pero ahora tengo que comentarlo pk osino el trexto sse pone denajo de la imagen
        min-height: 1px; 
        display: none; 
	z-index:1; 
        position: relative;
	}
	
        
        
        
        
.slick-slide img { display: block; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

/* Icons */
/*
@font-face { font-family: "slick"; src: url("./fonts/slick.eot"); src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg"); font-weight: normal; font-style: normal; }
*/

/* Arrows */

.slick-prev, .slick-next {  
	z-index:100; /* CARL */
	/* color:#f00; */ /* CARL */ 
	position: absolute;  
	display: block; 
	
	/*
	height: 20px; 
	width: 20px; 
	*/
	
	/*
   height: 40px; 
	width: 40px; 
	*/
        
	/* width: 60px;  */
	line-height: 0; font-size: 0; 
	cursor: pointer; 
	background: transparent; 
	// background-color:#999;
	// color: transparent; 
	/* color: #fff; */ /* TEST DE CARL - para el texto de los arrows - NO HA FUNCIONADO */
	top: 50%; 
	
	/*
	height: 20px; 
	width: 20px; 
	margin-top: -10px; 
	*/
	
	height: 40px; 
	width: 40px; 
	margin-top: -20px; 	

	padding: 0; 
	border: none; 
	outline: none; 
	}

.slick-prev:focus, .slick-next:focus { outline: none; }

.slick-prev.slick-disabled:before, 
.slick-next.slick-disabled:before { 
	opacity: 0.25; /* efecto interesante */ 
	display:none; /* TEST DE CARL */     
  z-index:0;
	}

.slick-prev:before, 
.slick-next:before { 
	// font-family: "slick"; 
	// font-size: 20px;  
	// font-size: 48px; 
	font-size: 64px;
	// font-family: "Impact"; 
	
	/* font-size: 60px; */ 
	// line-height: 1; 
	line-height: 0.6em; 
	// color: #fff; /* vip, color del no-arrow - err, del arrow*/
	
	color: #403e42;
	font-weight:bold;
	/* background-color:#f00; */
	/* padding:15px 20px; */
	
	display:block;
	/* height:100%; */
	// padding:10px 0px;
	
	// background-color: $c_main_dark;	
	// background-color: #FF5722; // orange accent color	

// background-color: #403e42;
// background-color: #00F;

	opacity: 0.85; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; 
	
	// @extend .s_buttonBase;
	// @extend .s_buttonBaseAlt;

	}
     
/* CARL - EFECTO HOVER */
/*
.slick-prev:hover:before, 
.slick-next:hover:before { 
	// background-color: $c_main;
	background-color: lighten(#FF5722, 5%);
	}
*/	
	
      
.slick-prev {left: 24px;}
.slick-prev:before { 
	// content: "<"; 
	/* font-family: 'Gentium Basic', sans-serif; */

	content:url('../img/icons/ui/icon-prev.png'); // TEST - real life size	
	// background-image: ; 
	// do same trick as in  inand out social buttons..
	// top: -4px; // TEST	
	// top: 2px;
	
	background-color: #ccc; // DEV
	background-image: url('../img/icons/ui/icon-prev.png'); // TO ADJUST THE SIZE
	// background-size: 20px 20px;
	// background-size: 18px 18px;  // 28px & 61px 
	background-size: 28px 61px;
	display: inline-block; // ?
	/*
	width: 20px; 
	height: 20px;
	*/
	// width: 18px; 
	// height: 18px;	
	width: 28px; 
	height: 61px;				
	} // 

.slick-next {right: 24px;}
.slick-next:before { 
	// content: ">"; 

	// background-image: url('../img/icons/ui/icon-prev.png');
	// background-size: 20px 20px;
	background-size: 18px 18px;
	display: inline-block;
	/*
	width: 20px; 
	height: 20px;
	*/
	width: 18px; 
	height: 18px;
	content:"";		
	
	content:url('../img/icons/ui/icon-next.png'); // TEST - real life size	
			

}








/* Dots */
/* .slick-slider { margin-bottom: 30px; } */


.slick-dots { 
	position: absolute; 
	// bottom: -45px; 
	bottom: 24px;
	z-index:101;
	
	list-style: none; display: block; text-align: center; padding: 0px; width: 100%; 
	
	
	// background-color: #f00;
	}


.slick-dots li { position: relative; display: inline-block; height: 20px; width: 20px; margin: 0px 5px; padding: 0px; cursor: pointer; 

border: 3px solid #403e42;
border-radius: 50%;
}


.slick-dots li button { 
border: 0; background: transparent; display: block; height: 20px; width: 20px; outline: none; line-height: 0; font-size: 0; color: transparent; padding: 5px; cursor: pointer; outline: none; 

// border: 3px solid #00f;

}

/* CARL - pk no iba el current .. */
.slick-dots li.slick-active { 
background-color: #403e42;
 }




.slick-dots li button:focus { outline: none; }

.slick-dots li button:before { position: absolute; top: 0; left: 0; content: "\2022"; width: 20px; height: 20px; font-family: "slick"; font-size: 6px; line-height: 20px; text-align: center; color: black; opacity: 0.25; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }

.slick-dots li.slick-active button:before { opacity: 0.75; }
