/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Buttons
 */



/* do sass for colors, and for interactiuon behaviours - UI */
/* do sass for interactiuon behaviours - UI */


	

	


// .pointer {cursor: pointer;} /* THIS IS TOO GENERIC !!!!!!!!! */






/* UI ELEMENTS / move to buttons file ================================================================================== */



.buttonStyleX { // MAYEB ADD TRASNITION SPEED !!!
	background-color:$c_primary_dark;
	&:hover {background-color: $c_primary;}		
	}


	
	
	
.button {
	cursor:pointer;
	@include transitionButton;
	@extend .buttonStyleX;
	font-weight: bold;
	color:#fff;	
	
	/* LAYOUT RELATED - SHOULDN'T BE HERE !!! */
	float:left;
	clear:left;
	margin-bottom: $spacing;
	}

	.buttonSmall {
		@extend .button;
		font-size: $fs_s;
		padding: $spacing025;
		border-radius: $spacing0125;
		}
	.buttonMedium {
		@extend .button;
		font-size: $fs_m;
		padding: $spacing025 $spacing05;
		border-radius: $spacing025;
		}	
	.buttonBig {
		@extend .button;
		font-size: $fs_l;
		padding: $spacing05 $spacing;
		border-radius: $spacing025;
		}		