/*
 * Project: eimoe
 * File: Header
 */

header {
	
  .inner {
		padding: $spacing025; 
		@include paddingv($spacing05);
		// overflow:hidden; // S&D WITH PSPAD - OVERLL - DEV FILE TASKS
		@extend .clearfix;
		position:relative;
		}
	
	} // header
	

	.navicon {	
		display: none;
		@media only screen and (max-width: $bp_l) { 
			display: block !important;	
			// display: inline-block !important;	
			} // MEDIA-QUERY	
			
		cursor:pointer;
		float:left;
		width:36px;
		// margin-top: 6px; // TEST // !!!!
		// margin-right: $spacing05; // TEMP
		img {@include img;}
		} // navicon	


.w_headerTop {
	// @include paddingv($spacing); // TEST
	@include paddingv(36px); // TEST
	} // w_headerTop
	
	
.headerSearch {
	float: right;
	// margin-top: 8px;
	margin-top: 2px;
	// font-size: $fs_m;
	// font-size: $fs_l; // TEST
	font-size: 24px; // TEST

	cursor: pointer;

	.icon,
	.inputHidden,
	.text {
		display: inline-block;
		vertical-align: middle;
		} // both   

	.icon {
	 //  border: 1px solid blue;
		margin-right: $spacing0125; // TEST
		width: 20px;
		img {
			display: block;
			// border: 1px solid red;
			} // img
		
		} // icon

  
	.inputHidden {
		// border: 1px solid green;
		// margin-right: $spacing0125; // TEST
		// display: none; // SHOW WITH JQUERY
		// &.is-visible {display: inline-block;}
		overflow: hidden; // :-) // BUT WHY ?!?!?!
		visibility: none;
		&.is-visible {visibility: visible;}
		
		// TIP: Tranisiton the width
		// @include transitionGeneral;
		@include transition(all $t_fast linear); 

		width: 0px;
		&.is-visible {
			overflow: visible;
			width: 140px;  
			// width: 500px;
			@include transition(all $t_base linear); // TEST, FOR A FASTER RETURN
			}

		} // inputHidden
  
    .text {
      // border: 1px solid orange; 
      // FONT IS ACTUALLY A BIT THICKER, NO???
      margin-left: $spacing0125; // TEST
      } // text

    // 1 - jquery to show X when hover on item
    // 2 - html o match it
  
	} // headerSearch




.headerText {
	clear: both;
	margin-top: $spacing2; // TEST
	margin-bottom: $spacing2; // TEST
	text-align: center;

	.logoMain { // USE FITTEXT !!!!!!!!!!!!!!!!!
		// font-size: $fs_xxl; 

		a {
			// font-size: 56px; 
			// line-height: 1.2em; // TEST
			line-height: 1em; // TEST
      font-size: 92px; // test  
			@include transitionGeneral; // ADD TO * 
			@include f_special;			
			color: inherit;
			// &:hover {color: $c_accent;}
			}
			
		} // logoMain
		

	.headerSlogan {
		// font-size: $fs_xl; 
		font-size: 42px; // test  
		letter-spacing: 0.1rem;		
		@include transitionGeneral; // ADD TO *	
		} // headerSlogan
				
	} // headerText


@media only screen and (max-width: $bp_l) { 
	.headerText .logoMain a {font-size: 72px;} // a		
	.headerText .headerSlogan {font-size: 36px;} // a					
	} // MEDIA-QUERY				
								
@media only screen and (max-width: $bp_m) { 
	.headerText .logoMain a {font-size: 64px;} // a					
	} // MEDIA-QUERY		

@media only screen and (max-width: $bp_s) { 
	.headerText .logoMain a {font-size: 50px;} // a		
	.headerText .headerSlogan {font-size: 30px;} // a				
	} // MEDIA-QUERY	

@media only screen and (max-width: $bp_xs) { 
	.headerText .logoMain a {font-size: 32px;} // a			
	.headerText .headerSlogan {font-size: 20px;} // a						
	} // MEDIA-QUERY	
	
	
	
	
/*  
 * 

*/ 	
	