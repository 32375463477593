/*
 * File: _modules
 */
 
 
.w_fullPage_slider {
	margin-bottom: $spacing; // TEST
	} // w_fullPage_slider

 
.l_fullPage_slide {
	/* BG IMG */
	@include bg_img;
	@include ar_16_9; // TEST - - - -
	// padding-top: 100%; // TEST
	// border: 3px solid red; // DEV

	@media only screen and (max-width: $bp_s) {
		@include ar_square; // TEST - - - -	
		} // MEDIA-QUERY
					
	} // l_fullPage_slide


.l_fullPage {
	// border: 3px solid black; // DEV
	} // l_fullPage




	.m_sliderCaption {
		position: absolute; width: 100%; bottom: 0px; 
		// padding: $spacing05; // TEST
		padding: $spacing; // TEST

		a {
			text-decoration: underline; /* CUSTOMIZED UNDERLINE */
			&:hover {text-decoration: none;}
			}
		
		.captionContent {
			background-color: #999; // DEFAULT, AS PRECAUTION
			// padding: $spacing05; // TEST
			padding: $spacing; // TEST
			/* TEXT */
			color: $c_white;	
			font-size: 32px; // TEST				
			} // captionContent
		
		@media only screen and (max-width: $bp_m) { // TEST
			// width: 100%; // TEST
			// right: $spacing05;	
			} // MEDIA-QUERY
			
		} // m_sliderCaption 
			
			
			
			
// slider arrows, smaller, in small box case 
// check also in big box case firts, to see if i need 2 scenarios or not ... 			
// smaller in general, just a little bit biggeer in big image than in slsmal boxes
// and make sute its vertically centered... 

// GRREEN SCHOOL LOGO, CRETA IT AT AS BG IMAGE CONTAIN, RIGHT, ON A CPAP AENCIMA DE LA IMAGEN DE GREEN SCHOOL.
			
			
			
			
 
.w_gridModule { 
	// border: 3px solid black;
	// margin-bottom: $spacing; // para que siempore hay espacio inferios... vsd paddng bottom del parent... 
	// margin-bottom: $spacing2; // NO PUEDE TENER MARGIN BOTTM, PORQUE EN PAGE VIDEREGAINEDE HAY SIN EPACIO DEBAJO
	// margin-bottom: $spacing3;

	// // GERIC NO PUENDE TENER MARGIN BOTTOM !! (EN AL HOEM NO QUIERO MARGIN BOTTOM.. 
	
	
	
		&.case-alternate {
			// border: 10px solid red; // TODO
			
			.item {

				.col {
					float: left;
					width: 50%;
					} // col
					
				&:nth-of-type(odd) .col {float: right;} // col

				@media only screen and (max-width: $bp_m) {
					.col {@include fullwidth;} // col
					} // MEDIA-QUERY
					
				} // item
				
			} // case-alternate
			

		&.case-not-alternate {
			// border: 10px solid blue; // TODO
			
			.item {
				.col {
					float: left;
					width: 50%;				
					} // col
				@media only screen and (max-width: $bp_m) { // TEST
					.col {@include fullwidth;} // col
					} // MEDIA-QUERY			
				} // item
				
				
				
				
			} // case-not-alternate
			
	
	&.case-flow {
		.item {
			margin-bottom: 0px; // RESET
			
			// @include paddingv($spacing);
			@media only screen and (max-width: $bp_m) { // TEST
				padding-top: $spacing;
				} // MEDIA-QUERY	
								
			} // item
		
		.item {

			/*
			.col {
				border: 1px solid red;
				}
			*/
			
			/*
			&:nth-of-type(odd) .frame .col {
				border: 1px solid blue;
				} // col
			*/
			
			&:nth-of-type(odd) .info {
				// padding: $spacing $spacing $spacing $spacing;
				// padding: $spacing2; // TEST
				padding-left: 0px; // TEST, MUST BE RESTEED LATER IN RWD...
				
				@media only screen and (max-width: $bp_m) {
					// padding: $spacing2;
					// padding: $spacing2 0px;
					// border: 10px solid red; // dev
					padding-left: $spacing2
					} // MEDIA-QUERY
				@media only screen and (max-width: $bp_s) {
					// padding: $spacing;
					// padding: $spacing 0px;
					padding-left: $spacing;
					} // MEDIA-QUERY
									 
				} // info
			
			
			} // item
		
		} // case-flow
	
			
			






	.item {
		@extend .clearfix;
		margin-bottom: 36px;
		&:last-of-type {margin-bottom: 0px;}
		
		/*
		.col {
			border: 1px solid red; // TODO
			}
		*/
		
		/* m_sliderCaption */

		.image {
			// BG IMAGE
			@include bg_img;
			// background-size: cover;
			@include ar_4_3; // DEMO - BETTER ASI PARA TRINN FOR TRINN
			// @include ar_16_9; // DEMO
			} // image
				
					
		.info {
			// background-color: $c_white; // decided on page
			// padding: $spacing $spacing025 $spacing $spacing;
			
			// padding: $spacing $spacing $spacing $spacing; // para que sea simetrico y me sirva en trinn for trinn... VS chanhe he item itself inside the grid...
			
			
			padding: $spacing2; // TEST
			
			.title { // default color ?? comes form body tag... 
				@include f_special;
				// font-size: $fs_xl; // test
				font-size: $fs_xxl; // test - is 34px
				font-size: 48px; // TEST
				line-height: 1.2em;
				
				// margin-bottom: $spacing05;
				margin-bottom: $spacing05;
				} // title
			
			.text {
				color: $c_black;
				// font-size: $fs_l; // test
				font-size: 24px; // TEST
				margin-bottom: $spacing05;
				} // text
			
			.readMore {
				color: $c_black;
				// font-size: $fs_s; 
				// font-size: $fs_m; // TEST
				font-size: 24px; // TEST
				
				span {
					cursor: pointer;
					text-decoration: underline;
					&:hover {text-decoration: none;}
					}
				} // readMore
			
			
			
			@media only screen and (max-width: $bp_m) {


				.title { // default color ?? comes form body tag... 
					@include f_special;
					// font-size: $fs_xl; // test
					font-size: $fs_xxl; // test - is 34px
					font-size: 48px; // TEST
					line-height: 1.2em;
					
					// margin-bottom: $spacing05;
					margin-bottom: $spacing05;
					
					
					
					font-size: $fs_xl; // RWD
					
					} // title
				
				.text {
					color: $c_black;
					// font-size: $fs_l; // test
					font-size: 24px; // TEST
					margin-bottom: $spacing05;
					} // text
				
				.readMore {
					color: $c_black;
					// font-size: $fs_s; 
					// font-size: $fs_m; // TEST
					font-size: 24px; // TEST
					
					span {
						cursor: pointer;
						text-decoration: underline;
						&:hover {text-decoration: none;}
						}
					} // readMore
			
			
			
			
				} // MEDIA-QUERY
					
					
								
			
			} // INFO
		
	
      
    /* DROPDOWN */ 
		.hiddenText {
			clear: both;
			display: none; // UI - SHOW WITH JQUERY
			// border: 3px solid red;
			background-color: $c_white;
			// padding: $spacing $spacing2;
			padding: $spacing2 $spacing4;
			padding-bottom: 0px; // POR EL CLOSE
			color: $c_black;
			font-size: $fs_l; // test
			
			// AT TEH END, ADD ANOTHER DIX, WHICH WILL CONTAIN THE CLOSE BUTTON, and manages the bottom padding
			.hiddenText_bottom {
				@include paddingv($spacing); // TEST
				
				.closeBox {
					cursor: pointer; // UI - JQUERY
					width: 32px;
					@include centered;
					img {@include img;}
					} // closeBox
				
				} // hiddenText_bottom

			} // hiddenText
	
      

			@media only screen and (max-width: $bp_m) { // TEST
				
				.image {
					// @include ar_4_3; // DEMO - BETTER ASI PARA TRINN FOR TRINN
					@include ar_16_9; // TEST - FOR LESS IMAGE HEIGHT
					} // image
							
					
									
				.hiddenText {
					@include paddingh($spacing2);
					} // hiddenText
					
				} // MEDIA-QUERY
		
			
			@media only screen and (max-width: $bp_s) { // TEST
				
				.info {
					@include paddingh($spacing);
					} // info
				
				.hiddenText {
					@include paddingh($spacing);
					@include paddingv($spacing);
					} // hiddenText
					
				} // MEDIA-QUERY
				
											
		} // item


} // w_gridModule


 
 
 
 
.m_textHeroLateral { // m_textHeroTeacher
	// border: 3px solid red; // DEV
	@extend .clearfix;
	margin-bottom: $spacing2; // TEST
	
	.textHeroLateral_text { // m_textHeroTeacher
		float: left; // 
		width: 68%; // test
		// margin-bottom: $spacing2; // TEST - - -- 
		// font-size: $fs_l; // TEST
		// line-height: 1.6rem; // TEST
		font-size: $fs_xl; // TEST
		line-height: 2rem; // TEST

		color: $c_black;
		
		// @include transitionGeneral;
		@media only screen and (max-width: $bp_m) {
			width: 100%
			} // MEDIA-QUERY
			
		
		a {
			text-decoration: underline;
			&:hover {text-decoration: none;}
			}	
								
		} // textHeroLateral_text
		
		
	// only used in single  - but could be kinda used in opptak vare skoler ... chek later el hack de ahi...  porquees absoluet wtf 	
	.w_imageSingle {
		float: right; 
		// width: 20%;
		width: 27%; // is 5% separation form text
		
		.m_imageSingle {
			@include bg_img;
			@include ar_4_3; // TEST
			} // m_imageSingle
		
		} // w_imageSingle
		
	
	
	
	@media only screen and (max-width: $bp_m) {
	
		.textHeroLateral_text {
			@include fullwidth;
			} // textHeroLateral_text	
			
		.w_imageSingle {
			@include fullwidth;
			} // w_imageSingle
		
		} // MEDIA-QUERY
			
			
						
  } // m_textHeroLateral












	
	








.m_ourSchools {
	@extend .clearfix;
	margin-bottom: $spacing4; // TEST
	
	.m_textHeroLateral {float: left;} // CASE: OUR SCHOOLS
	
	.circle {
		// float: left;
		float: right;
		margin-top: -84px; // TEST - MUST BE RESETTED AFTERWARDS
		@include circle(240px);
		background-color: $c_white;
		color: $c_orange;
		text-align: center;

		.w_lines {
			@include vcenter; 
			@include paddingh(12px);
			
			.line1 {
				@include f_special;
				font-size: $fs_xl;			
				} // line1
			.line2 {
				font-size: $fs_l;	
				} // line2
		
			} // w_lines
			
		} // circle
	
	
	@media only screen and (max-width: $bp_m) {
		
	.m_textHeroLateral { // CASE: OUR SCHOOLS
		float: none;
		} // m_textHeroLateral
		
	.circle {
		margin-top: 0px; // RESET
		float: none;
		@include circle(240px);
		@include centered;

		.w_lines {
			@include vcenter; 
			@include paddingh(12px);
			
			.line1 {
				@include f_special;
				font-size: $fs_l;			
				} // line1
			.line2 {
				
				} // line2
		
			} // w_lines
			
		} // circle
		
		} // MEDIA-QUERY

	
	} // m_ourSchools 
	
	
	
	
/* PAGE - HOME */	
.m_loadMore {
	clear: both;
	margin-bottom: $spacing2; // TEMP
			
	text-align: center;
	color: $c_black;
	// font-size: $fs_xs; // even samller, But too small
	// font-size: 11px; // TEST
	
	// font-size: $fs_s;
	// font-size: $fs_m;
	font-size: 20px;
	
	span {
		cursor: pointer;
		text-decoration: underline;
		&:hover {text-decoration: none;}
		} // span
		
	a {
		// cursor: pointer;
		text-decoration: underline;
		&:hover {text-decoration: none;}
		} // a
		
	} // m_loadMore
	
	
		
		
		
		
.w_blogPosts {
  @extend .clearfix;
  // margin-bottom: $spacing2; // TEST
  padding-bottom: $spacing2; // TEST
	
	/*
	 CASE - HOME
	 SCENARIO: LOAD MORE - OJO, 1ST IOTEM IS OUTSIDE, SO OJO !!!
	 */
		&.case-loadMore {

			.jqTrigger_loadMore_2,
		  .jqTrigger_loadMore_3,
		 	.jqTrigger_loadMore_last {display: none;} // UI - SHOW WITH JQUERY
			
			.col {
				display: none;
				
				&:nth-of-type(1),
				&:nth-of-type(2),
				&:nth-of-type(3) {display: block;} // UI - SHOW WITH JQUERY
				
				@media only screen and (max-width: $bp_m) { // 2 COLS
		
					} // MEDIA-QUERY	
					
				@media only screen and (max-width: $bp_s) { // 1 COL
		
					} // MEDIA-QUERY
							
				} // col
				
			} // case-loadMore
		
	
	
	
	
	
	
	/*
	&.case-home-hide-first {
		
		.col:first-of-type {display: none;}
		
		} // case-home-hide-first
	*/
	
	
  .col {
    float: left;
    width: 30%;
    margin-right: 5%; // SITE STANDRAD
    
    &:nth-of-type(3n) {margin-right: 0px;}   
    &:nth-of-type(3n+1) {clear: left;} // TEST 
    
		margin-bottom: 5%;
		&:last-of-type {margin-bottom: 0px;}  // PRECAUTION
    
    @media only screen and (max-width: $bp_m) { // 2 COLS
			width: 47.5%;
			margin-right: 5%;
			&:nth-of-type(3n) {margin-right: 5%;}   
			&:nth-of-type(3n+1) {clear: none;} // TEST 
			&:nth-of-type(2n) {margin-right: 0px;}   
			&:nth-of-type(2n+1) {clear: left;} // TEST 
      } // MEDIA-QUERY	
			
    @media only screen and (max-width: $bp_s) { // 1 COL
			@include fullwidth;
			margin-right: 0%; 
			margin-bottom: $spacing2; // TEST;
      } // MEDIA-QUERY
					
    } // col
  
	
  
  .item {
        
    .w_image {
      position: relative; 

			&.is-active {
				
				.imageCover {
					background-color: rgba(#000, 0.20);
					} // imageCover
			
				} // IS
			
      .image {
        @include bg_img;
        @include ar_4_3; // TEST APROX
        } // image
      
      .imageCover {
        position: absolute; top: 0px; width: 100%; height: 100%;
				@include transitionGeneral;
				background-color: rgba(#000, 0.00);
				
        a {
          display: block;
          height: 100%;          
          } // a
        } // imageCover
      
      } // w_image
    
    
    .info { 
      color: $c_black;
      padding-top: $spacing05; // TEST
      
      .title {
        @include f_main_bold;
        // font-size: $fs_l; // TEST
				// font-size: 24px; // TEST
				font-size: 30px; // TEST
        // margin-bottom: $spacing05; // TEST
				margin-bottom: $spacing025; // TEST
        } // title
      
      
      .text {
				font-size: 24px; // TEST
        margin-bottom: $spacing025; // TEST
        
        }
      
      .readMore { 
        font-size: 24px; // TEST
				
        a {
          text-decoration: underline;
          &:hover,
					&.is-active {text-decoration: none;}
          }
        
        } // readMore
      
      
      } // info
    
    
    } // item
  
} // w_blogPosts






.w_listResults {
  @extend .clearfix;
  // padding-bottom: $spacing2; // TEST
	padding-bottom: $spacing; // TEST
	// padding-top: $spacing; // TEST
	padding-top: $spacing05; // TEST

  .item { 
		// margin-bottom: $spacing;
		// margin-bottom: $spacing2;
		margin-bottom: 36px;
		&:last-of-type {margin-bottom: 0px;}  // PRECAUTION		
	
		.title {
			@include f_main_bold;
			font-size: $fs_l; // TEST
			margin-bottom: $spacing05; // TEST
			
			a {
				text-decoration: underline;
				&:hover {text-decoration: none;}
				}
							
			} // title
		
		.text {
			margin-bottom: $spacing025; // TEST
			}
		
		.readMore { 
			
			a {
				text-decoration: underline;
				&:hover {text-decoration: none;}
				}
			
			} // readMore

    
    } // item
  
} // w_blogPosts		




/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Content - MOSTLY DEMO STUFF TO CLEAR AWAY AND PROCESS
 */
 

.w_teacherJobs {
 	@extend .clearfix;
	margin-top: $spacing2; // TEST
  margin-bottom: $spacing2; // TEST
  color: $c_black;
  
  .col {
    float: left;
    width: 50%; // TEST
		&:first-of-type {padding-right: $spacing;} // first
    } // col
  
	@media only screen and (max-width: $bp_m) { // 1 col

		.col {
			@include fullwidth;
			&:first-of-type {
				padding-right: 0px; // RESET
				margin-bottom: $spacing2; // test
				} // FIRST
			} // col
		
		} // MEDIA-QUERY
		
			
			  
  .m_jobsListing {
    
    .title {
      @include f_special;  
      font-size: $fs_xl; // TEST, OK
      margin-bottom: $spacing025; // TEST
      }
    
    li {
      margin-bottom: $spacing025; // TEST
      &:last-of-type {margin-bottom: 0px;}
			
			
			font-size: $fs_l; // TEST, OK
			
      } // li
    
    a {
      text-decoration: underline;
      &:hover {text-decoration: none;}
      } // a
    
    
    } // m_jobsListing
  
  
  
  .m_jobsContact {
    margin-bottom: 36px; 
		
    .title {
      @include f_main_bold;  
      font-size: $fs_l; // TEST, OK
      margin-bottom: $spacing025; // TEST
      }
    
    p,ul  {
			font-size: $fs_l; // TEST, OK
      }	

    p {
      margin-bottom: $spacing025; // TEST
      }

    a {
      text-decoration: underline;
      &:hover {text-decoration: none;}
      } // a
    
    ul {
      list-style: disc;
      padding-left: 16px; // TEST, nice reset to basic.. vs initialize etc !!!!
      }
    
    li {
      margin-bottom: $spacing0125; // TEST
      &:last-of-type {margin-bottom: 0px;}
      } // li
    
    
    
    } // m_jobsContact 
  
  
  } // m_textHeroTeacher






 
 



 
 
 
/* 
ONLY USED ONCE
PAGE: Læreplan
*/
.w_gridPDF { 
	// margin-top: $spacing2; // test
	margin-bottom: $spacing2; // test
	@extend .clearfix;
	
	.col {
		float: left;
		width: 30%;
		margin-right: 5%;
		&:nth-of-type(3n) {margin-right: 0px;}	
		margin-bottom: 5%;
		&:last-of-type {margin-bottom: 0px;} // PRECAUTION	      
		} // col
		
  
  
  @media only screen and (max-width: $bp_m) { // TEST

	.col {
        @include fullwidth; // RESET
		margin-right: 0%; // RESET
        margin-bottom: $spacing; // TEST		
		} // col          
          
    } // MEDIA-QUERY	
  
  
  
  
  
	.item {
	  // SCRIPT USED TO FORCE EQUALHEIGHT
      min-height: 200px; // TEST
      padding: $spacing; // TEST
      // padding: $spacing075; // TEST
      
		.info { 
			position: relative;
			// border: 1px solid green; // DEV
			height: 100%; // OK, trick
			
			.text {
				color: $c_white;
				// font-size: $fs_l; // test
				font-size: $fs_xl; // test
				// margin-bottom: $spacing05;
				// margin-bottom: $spacing4;
				// margin-bottom: $spacing6;
				margin-bottom: $spacing8;
				
				text-decoration: underline;
				&.is-active {text-decoration: none;}
				
				} // text
	
			.icon {
				// border: 1px solid red; // DEV
				position: absolute; bottom: 0px; right: 0px; 
				width: 32px; // TEST
				img {@include img;}
				} // icon
				
		  } // info
		
		
		
		@media only screen and (max-width: $bp_l) { // TEST
			min-height: auto; // TEST
			
			.info { 
				
				.text {
					// font-size: $fs_xl; // test
					font-size: $fs_l; // test
					} // text
	
				} // info
				
			
			} // MEDIA-QUERY
			
				
		@media only screen and (max-width: $bp_m) { // TEST
			min-height: auto; // TEST
			
			.info { 
				
				.text {
					margin-bottom: $spacing4;
					} // text
	
				} // info
			
			
			} // MEDIA-QUERY	
		
		
		
		} // item
	
				
} // w_gridPDF
 
 
 
 


.w_gridNews { 
	margin-top: $spacing2; // test
	margin-bottom: $spacing2; // test
	@extend .clearfix;
	
	.col {
		float: left;
		width: 30%;
		margin-right: 5%;
		&:nth-of-type(3n) {margin-right: 0px;}			
		} // col
			
	.item {
		
		.image {
			// BG IMAGE
			@include bg_img;
			// background-size: cover;
			// @include ar_4_3; // DEMO
			@include ar_16_9; // DEMO
			margin-bottom: $spacing05;
			} // image
					
		.info {
			// background-color: $c_white; // decided on page
			// padding: $spacing $spacing025 $spacing $spacing;
			color: $c_black;
			
			.title { 
				font-size: $fs_l; // test
				font-weigt: bold;
				margin-bottom: $spacing05;
				} // title
			
			.text {
				margin-bottom: $spacing05;
				} // text
			
			.readMore {
				font-size: $fs_s; 
				
				span {
					cursor: pointer;
					text-decoration: underline;
					&:hover {text-decoration: none;}
					}
				} // readMore
			
			
			} // text
		
		
		} // item



		
				
} // w_gridNews







.w_gridSchools {  // w_listXXX
	// margin-top: $spacing2; // test
	margin-bottom: $spacing2; // test

	.col {
		float: left;
		width: 23.5%;
		margin-right: 2%;
		&:nth-of-type(4) {margin-right: 0px;}	
		
		@media only screen and (max-width: $bp_m) { // TEST
		
			&:nth-of-type(1) {
				@include fullwidth;	
				margin-right: 0%;
				margin-bottom: $spacing05; // TEST
				}	
			
			width: 32%;
			// @include fullwidth;	
			// margin-bottom: $spacing;
			margin-bottom: $spacing05; // TEST
			&:last-of-type {margin-bottom: 0%;} // PRECAUTION							
			} // MEDIA-QUERY	
			
			
		@media only screen and (max-width: $bp_s) { // TEST - some issues here !!! but kind alike how it behaves
		
			&:nth-of-type(1) {
				width: 49%;	
				margin-right: 2%;
				// margin-bottom: $spacing05; // TEST
				}	
			
			width: 49%;
			// &:nth-of-type(4) {margin-right: 0px;}	
			&:nth-of-type(2n) {margin-right: 0px;}
			
			margin-bottom: $spacing05; // TEST						
			} // MEDIA-QUERY	
			
						
		@media only screen and (max-width: $bp_xs) { // TEST
			@include fullwidth;	
			margin-right: 0%;
			margin-bottom: $spacing05; // TEST
			&:last-of-type {margin-bottom: 0%;} // PRECAUTION							
			} // MEDIA-QUERY	
			
								
		} // col
			
			
			
			
			
	.item {
		margin-bottom: $spacing; // TEST
		&:last-of-type {margin-bottom: 0px;}	
		
		
		@media only screen and (max-width: $bp_m) { // TEST
			margin-bottom: $spacing2; // TEST						
			} // MEDIA-QUERY	
			
			
						
		@extend .clearfix;
		
	
			color: $c_black;
			
			.schoolName { 
				// font-size: $fs_l; // test
				font-size: $fs_xl; // test
				font-weigt: bold;
				a {
					text-decoration: underline;
					&:hover {text-decoration: none;}
					} // a				
				} // schoolName
			
			.text {
				// font-size: $fs_s; // TEST
				font-size: $fs_m; // TEST
				line-height: 1.2rem;
				
				.title {
					@include f_main_bold;
					} // title // div vs span
			
				a {
					text-decoration: underline;
					&:hover {text-decoration: none;}
					} // a
				
				} // text

		
		} // item

				
} // w_gridSchools






