/* CSS Document */

/* ============================================ */
/* COWOWO ACCORDION                             */
/* ============================================ */

/* CONTAINER */
#accordion_wrapper {

}

	/* EACH PAIR OF TRIGGER AND HIDDEN SECTION */
	.accordion_item {
	background-color:$c_primary;
	margin-bottom:5px;
	}
		/* TRIGGER */
		.accordion_top {
		background-color:$c_primary_light;
		}
			.accordion_top.active {
			background-color:#0f0 !important;
			}		
		
		/* HIDDEN SECTION */
		.accordion_bottom {
		background-color:$c_primary_dark;
		height:50px; /* DEMO ONLY */
		/* display:none; */ /* DO WITH CSS OR WITH JS.. simpler to be done through css, it's more failproof */
		}
