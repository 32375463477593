/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: Footer
 */


.m_scrollToTop {
	text-align: center;
	
	// border: 3px solid red; // DEV
	// @include paddingv($spacing); // TEST
	// @include paddingv($spacing2); // TEST
	@include paddingv(36px); // TEST
	
	a.scrollToTop {
     color: inherit;
     cursor: pointer;
     &:hover {color: $c_primary_light;}

		.icon {
			width: 48px;
			// width: 36px;
			@include centered;
			img {@include img;}
			} // icon

    } // a		
	
	} // m_scrollToTop



footer {
	@extend .clearfix;
	
	.inner {
		background-color: $c_bg_footer;
		@extend .clearfix;
		padding: $spacing05;
		@include paddingv($spacing05); // paddingh managed by frame
        // @include paddingv($spacing2);
        @include paddingv($spacing3);
		// specific    
    color:#fff; // very unspecific		
		}
			
	} // footer






.w_footerGrid {
	
	@extend .clearfix;
	// border: 1px solid red;
	color:#fff; 
	// font-size: 11px; // TEST
	// font-size: 13px; // TEST
  
		// https://stackoverflow.com/questions/6865194/fluid-width-with-equally-spaced-divs
    /*
		display: flex;
    justify-content: space-between; // apply this to footer grid !!!! en wide system..
		*/
 
	.col {
		float: left;
		width: 23.5%; 
		margin-right: 2%;
		&:nth-of-type(4) {margin-right: 0px;}
		
		@media only screen and (max-width: $bp_m) { // 2 col
			width: 49%; 
			&:nth-of-type(2) {margin-right: 0px;}
			margin-bottom: $spacing2; // TEST
			&:nth-of-type(4) {margin-bottom: 0px;} // PRECAUTION		
			
			font-size: $fs_l; // TEST
				
			} // MEDIA-QUERY	

		@media only screen and (max-width: $bp_s) { // 1 col
			@include fullwidth;
			margin-right: 0%;	
			} // MEDIA-QUERY	
			
		} // col
	
	
	
	
	
	
	.item {   
	
		.logoFooter {
			width: 192px; // TEST
			max-width: 100%; // wtf 
			img {@include img;}
			// margin-bottom: $spacing05;
			// margin-bottom: $spacing075;
			margin-bottom: $spacing;
			} // logoFooter
      

		.icon {
			width: 28px; // TEST
			img {@include img;}
			margin-top: $spacing05;
			} // icon
      
      
		
		// item
		font-size: 20px; // TEST	
			
	.title,
      h3 {
		margin-bottom: $spacing0125;
		
		// h3 reset
		font-weight: normal;
		@include f_main_bold;
		}
      
      
      
	/*
	.text {
		margin-bottom: $spacing025;

      ul {}

		li {
			margin-bottom: $spacing0125;
			&:last-of-type {margin-bottom: 0px;}
			} // a
		a {
			text-decoration: underline;
			&:hover {text-decoration: none;}
			} // a
		
		} // text
	*/
      
      ul {}
      
      
      
		li {
			margin-bottom: $spacing0125;
			&:last-of-type {margin-bottom: 0px;}
			} // a
		
		a {
			text-decoration: underline;
			&:hover {text-decoration: none;}
			} // a
      
      
      	// !!! HIDE "HOME" A LO CUTRE
		/*
		ul.menu > li:first-of-type {display: none;}
    */  
      
        // !!! HIDE SECONDARY LEVEL
		ul.menu > li ul {display: none;}
      
      
	.social {}
	
	
	} // item
	
	
	
	
	} // w_footerGrid

	