/*
 * Author: Carl Johansson @carlwebdev
 * Project: cowo-Boilerplate
 * File: a grid ... 
 */


.l_lateralNav {
	// border: 1px solid red;
	@extend .clearfix;
	// margin-bottom: $spacing4; // TEST
	margin-bottom: $spacing2; // TEST
  
	
	// margin-top: $spacing2; // TEST
	margin-top: 36px; // TEST
	
	
	/* NAV ELATERAL ADD THE ARROW AS PSEUD ELEMENT !!!! */
	
	
	nav.lateral { // TURN INTO TYPE SELECT BOX IN RESPOSNIVE !!!
		float: left;
    // width: 300px; // TEST
		width: 340px; // TEST
		
		
		ul {}
		
		li {
			margin-bottom: $spacing025;
			&:last-of-type {margin-bottom: 0px;}
			} // li
		
		a {
      // font-size: $fs_l; // TEST - same as main nav
			font-size: 24px; // TEST - same as new nav 
			text-decoration: underline;
			
			
			position: relative; 
			
			&:hover {
				// @include f_main_bold; // OJO CON GLITCH !!!
				text-decoration: none; // CARL - FOR COHERENCE WITH NEW NAV DESUGN BY EIGIL
				} // hover & active 
       
			 
			&.is-active {
				text-decoration: none; // CARL - FOR COHERENCE WITH NEW NAV DESUGN BY EIGIL
				@include f_main_bold;
				
				
				&::before {
					/* the triangle ... name as blue arrow nav"" */
					// content: '>';
					position: absolute; 
					left: -24px; 
					
					// content:url('../img/icons/ui/icon-nav.png');
					
					background-image: url('../img/icons/ui/icon-nav.png');
					width: 13px; 
					height: 24px;
					 
// background-image: url("../img/icons/ui/png/time.png");
background-size: 13px 24px;
display: inline-block; // 
width: 13px;
height: 24px;
content: "";		

// MAKE SMALLER !!!!!!!!!
			 
					 
					} // before
					
				} // hover & active 
				
							    
			} // a
		
		} // nav.lateral
	
	
	.lateralText {
		float: left;
		// width: calc(100% - 300px);
		width: calc(100% - 340px);
		color: $c_black;
		// font-size: $fs_l; // TEST
		font-size: 24px; // TEST
		
		p:first-of-type {
			// font-size: $fs_l; // TEST
			
			
			} //
		
		
			
		ul {}
		
		li {}
		
		a {}
		
		} // lateralText
	
	
  
    // RWD - TEXT FULL WIDTH, BUT NAV, IMPEMENTY LIKS A SELECT TYPE BOX... BUILD IT MYSELF.
  
    @media only screen and (max-width: $bp_m) { // TEST

      nav.lateral { 
        // TURN INTO TYPE SELECT BOX IN RESPOSNIVE !!! 
        // done - BUT NEED CURRENT, 
        // done - SO ALSO BUILD CURRENT PAGE FOR BOLD EFFECT
          @include fullwidth;
          margin-bottom: $spacing; // TEST
          } // nav.lateral

      .lateralText {
          @include fullwidth;
          } // lateralText       

      } // MEDIA-QUERY	


	} // l_lateralNav





.m_featuredImage {
  // border: 1px solid red; // DEV
  @include bg_img;
  @include ar_cinemascope; // TEST
  margin-bottom: $spacing2; // TEST
	margin-bottom: $spacing3; // TEST new standrad
	
	position: relative;

	.m_logo_greenSchool {
		position: absolute; top: 0px; 
		// width: 100%; 
		height: 100%;
		right: 0px;
		// border: 10px solid red; // DEV
		
		.logo {
			@include vcenter; // TEST
			width: 300px; // dev 
			img {@include img;}
			right: 24px;
			// border: 1px solid red; // DEV
			} // logo
		
		} // m_logo_greenSchool
	
	
	
	} // m_featuredImage



// PAGE SPECIFIC MODULE - LAEREPLAN - DOWNLOAD PDFS.. where did i oput the coeer???
// _CONTENT.SCSS - THATS WHERE IT IS LOCATED, THE CSS CODE.







// PAGE: GREEN PROJECTS

// BUT GENERIC LAYOUT FOR NLOG POSTS


// MOVE TO MODULES





