/* CSS Document - SASS */
/* Front-End */


/* 
 * SASS - VARIABLES - Colors 
 */
$c_black: #000; /* black */
$c_white: #fff; /* white */
 
 
$c_primary: #FF5722;
$c_primary_dark: #E64A19; 
$c_primary_light: #FFCCBC; 

$c_accent: #03A9F4;


$c_text: #212121;
$c_text_2: #727272;
$c_divider: #B6B6B6;


$c_blue: #525F98; // TExT ETC


// PAGE BG COLORS
// 1 home & pedagogikk
$c_bg_main: #E3ECEF;
//2 vare skoler (salmoncito)
$c_bg_schools: #FEF5EB;
// 3 - high school - yelllow
$c_bg_highschool: #FEFAE1;
// 4 - bli laerer - green
$c_bg_teacher: #E0F1E7;
// 5 - logg in - salmon dark
$c_bg_login: #FDE6CE;
// 6 - footer - c_blue.. not
$c_bg_footer: #38406C;


$c_orange: #F47A76;


/* 
 * SASS - VARIABLES - Font size 
 */
$fs_xxl: 34px;
$fs_xl: 28px;
$fs_l: 20px;
$fs_m: 16px;
$fs_s: 12px;
$fs_xs: 10px;

/* 
 * FALLBACKS using Sass
 */
 

/* CREATE MIXIN for rem fontsize and px fallback solution */

/* FALLBACK for background opacity */
	#asfgasafasf {
		background: #ccc; /* Fall-back for brosers that don't support opacity */
		background: rgba(204, 204, 204, 0.85);

	}


// create mixin - CSS_FALLBACK_tricks - LINK - the RGB fallback color only works when using shorthand. 
// http://css-tricks.com/ie-background-rgb-bug/


